import React, {FC} from "react";
interface IProps {
    item: {
        tag: string;
        headingText: string;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
    }
}

const Heading: FC<IProps> = (
    {
        item: {
            tag,
            headingText,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {}
        }
    }
) => {
    return <div data-aos={dataAos} style={parentStyles}>
        <div style={styles} dangerouslySetInnerHTML={{ __html: `<${tag}>${headingText}</${tag}>` }}/>
    </div>
}

export default Heading;
