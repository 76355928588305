import React, {Fragment, ReactNode} from "react";
import {FC} from "preact/compat";
import Link from "next/link";
import Image from "next/image";
import {useDispatch} from "react-redux";
import {setBuilderUrl, setPopup, setPopupName} from "../../../store/general";
import {domainUrl} from "../../../helper";

interface IProps {
    item: {
        switchValue: boolean | null;
        linkValue?: Record<string, string | null>;
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        },
        imageStyles: {width: "150%", marginLeft: "-25%"} | {};
        imageSwitchStyles: Record<string, string>;
        setInlineStyles: Record<string, string>
        linkTarget: "_self" | "_blank"
        defaultImage: string
    };
    dbName: string;
}

const ImageUpload: FC<IProps> = (
    {
        item: {
            switchValue,
            linkValue: { value: existsLinkVal = null } = {},
            linkTarget,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                parentStyles = {},
                styles: imageSwitchStyles,
            } = {},
            imageStyles,
            defaultImage
        },
        dbName
    }
) => {
    const dispatch = useDispatch();
    const handleModalOpen = (): void => {
        if (switchValue) {
            dispatch(setPopupName("zoom_in"));
            dispatch(setPopup(true));
            dispatch(setBuilderUrl(domainUrl(`${dbName}${defaultImage}`)))
        }
    }

    const imagePropHandler = {
        "true": {},
        "false": { onClick: handleModalOpen},
    }
    const LinkLayoutToggle = ({ children }: { children: ReactNode }) => {
        const jsxWithLayout = {
            "true": <Link href={existsLinkVal as string}>
                <a target={linkTarget}>
                    {children}
                </a>
            </Link>,
            "false": <Fragment>{children}</Fragment>
        }
        return jsxWithLayout[`${!!existsLinkVal}`]
    }

    return <div data-aos={dataAos} style={parentStyles}>
        <LinkLayoutToggle>
            <div style={{...imageSwitchStyles, ...imageStyles}}>
                <Image
                    src={defaultImage}
                    alt="builder image"
                    layout="fill"
                    loading="lazy"
                    style={{...imageSwitchStyles, ...imageStyles}}
                    {...imagePropHandler[`${!!existsLinkVal}`]}
                />
            </div>
        </LinkLayoutToggle>
    </div>
};

export default ImageUpload;