import React, {FC, Fragment, useRef} from 'react'
import cls from "../builder.module.scss"
import Script from "next/script";
import {schemaString} from "../../schemaorg";

interface IProps {
    handleToggle: (index: number) => void;
    active: number;
    setStyles: any;
    faq: {title: string, id: number, description: string}
}

const AccordionLIst: FC<IProps> = (
    {
        handleToggle,
        active = 0,
        faq: {title, id, description},
        setStyles
    }
) => {
    const contentEl = useRef<HTMLDivElement | null>(null);
    const schema = schemaString(title, description)
    const activeClsBoxHandle = {
        "true": {
            accordion: cls.active,
            collapse: cls.show
        },
        "false": {
            accordion: "",
            collapse: ""
        }
    }
    const activeBoxStyleHeight = {
        "true": {height: contentEl.current?.scrollHeight},
        "false": {height: "0px"}
    }
    return (
        <Fragment>
            <Script
                id={schema.name}
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schema)}}
            />
            <div className={cls["rc-accordion-card"]}>
                <div
                    className={`${cls["rc-accordion-toggle"]}
                    ${activeClsBoxHandle[`${active === id}`].accordion}`}
                    onClick={() => handleToggle(id)}
                >
                    <h5 className={cls["rc-accordion-title"]} style={setStyles}>{title}</h5>
                    <div className={cls["rc-accordion-icon"]}/>
                </div>
                <div
                    ref={contentEl}
                    className={`${cls["rc-collapse"]} ${activeClsBoxHandle[`${active === id}`].collapse}`}
                    style={activeBoxStyleHeight[`${active === id}`]}
                >
                    <div className={cls["rc-accordion-body"]}>
                        <p style={setStyles}>{description}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

export default AccordionLIst