import React, {FC, useState} from "react";
import AccordionLIst from './AccordionLIst'
import cls from "../builder.module.scss"
interface IProps {
    item: {
        id: number;
        title: string;
        description: string;
        accordionFaq: Array<{
            title: string;
            id: number;
            description: string;
        }>
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: any;
            parentStyles: any
        }
    }
}

const Accordion: FC<IProps> = (
    {
        item: {
            id,
            title,
            description,
            accordionFaq,
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {}
        }
    }
) => {
    const [active, setActive] = useState<number>(0);

    const handleToggle = (index: number) => {
        if (active === index) {
            setActive(0);
        } else {
            setActive(index);
        }
    }

    return <div key={id} data-aos={dataAos} style={parentStyles}>
        <div className={cls["card-faq"]}>
            {title}
            {description}
            {accordionFaq.map((faq, index: number) => (
                <AccordionLIst
                    key={index}
                    active={active}
                    handleToggle={handleToggle}
                    setStyles={styles}
                    faq={faq}
                />
            ))}
        </div>
    </div>
};

export default Accordion