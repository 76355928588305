import React, {FC, useEffect, useRef, useState} from "react";
import useIsInViewport from "../../../hooks/useIsInViewPort";

interface IProps {
    item: {
        videoLink: string
        convertedSettings: {
            animationAos: { "data-aos": any },
            styles: Record<string, string>;
            parentStyles: Record<string, string>
        }
    }
}

const Video: FC<IProps> = (props) => {
    const {
        item: {
            convertedSettings: {
                animationAos: {"data-aos": dataAos = null} = {},
                styles = {},
                parentStyles = {}
            } = {},
            videoLink
        }
    } = props
    const [link, setLink] = useState("")
    const viewRef = useRef(null)
    const isInViewport = useIsInViewport(viewRef);
    const [needLoad, setNeedLoad] = useState(false)

    useEffect(() => {
        setLink(videoLink)
    }, [])

    useEffect(() => {
        if (isInViewport) {
            setNeedLoad(true)
        }
    }, [isInViewport])


    return (
        <div
            data-aos={dataAos}
            style={parentStyles}
            ref={viewRef}
        >
            {needLoad &&
                <iframe
                    src={link}
                    allow="autoplay"
                    width="100%"
                    height={styles?.height || "200px"}
                />
            }
        </div>
    );
}

export default Video