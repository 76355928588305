import React, {ReactElement} from "react";
import Slider from "react-slick";
import {domainUrl} from "../../../helper";
import defaultBuilderImage from "../../../images/slider_placeholder.jpg";
import cls from "./builder_slider.module.scss"
import Image from "next/image";
import {SliderNextArrow, SliderPrevArrow} from "../../../svg";

interface IProps {
    dbName: string,
    item: {
        slider: {
            slides: Array<{
                id: string,
                slider_path: string,
                content: {
                    description: string,
                    buttonText: string,
                    link: string,
                    title: string,
                    link_target: boolean,
                    showDescription: "Desc" | "Empty";
                    showButtonText: "BtnText" | "Empty";
                    buttonLinkTarget: "_blank" | ""
                },
                style: {
                    horizontal: string,
                    vertical: string,
                    textAlign: string,
                    contentColor: string,
                    contentAnimation: string,
                }
            }>
            settings: Record<string, string | number | ReactElement>
        }
    }
}

const SliderComponent = (props: IProps) => {
    const {item, dbName} = props

    return <div className={cls.slider} data-aos={item.slider.settings.animation} style={{height: item.slider.settings.height as number}}>
        <Slider
            {...item.slider.settings}
            nextArrow={<SliderNextArrow/>}
            prevArrow={<SliderPrevArrow/>}
        >
            {item.slider.slides.map(slide => {
                const {
                    title = "",
                    description = "",
                    buttonText = "",
                    link = "",
                    showDescription,
                    buttonLinkTarget,
                    showButtonText
                } = slide.content
                const {contentAnimation, contentColor, horizontal, vertical, textAlign} = slide.style;
                const ShowDesc = () => {
                    const descHandler = {
                        "Desc": <div className={cls.content_description}>
                            {description}
                        </div>,
                        "Empty": null
                    }
                    return descHandler[showDescription]
                }
                const ShowBtn = () => {
                    const btnHandler = {
                        "BtnText": <a href={link} target={buttonLinkTarget} className={cls.content_button}>
                            {buttonText}
                        </a>,
                        "Empty": null
                    }
                    return btnHandler[showButtonText]
                }

                return <div className={cls.slide_wrapper}>
                    <Image
                        alt="slider image"
                        aria-hidden="true"
                        width={500}
                        height={500}
                        src={slide.slider_path}
                    />
                    <div
                        className={cls.content_wrapper}
                        style={{
                            justifyContent: horizontal,
                            alignItems: vertical,
                        }}
                    >
                        <div
                            className={cls.content}
                            data-aos={contentAnimation}
                            style={{
                                color: contentColor,
                                alignItems: textAlign
                            }}
                        >
                            <div className={cls.content_title}>{title}</div>
                            <ShowDesc />
                            <ShowBtn />
                        </div>
                    </div>
                </div>
            })}
        </Slider>
    </div>
}


export default SliderComponent